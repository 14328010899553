
.header {
    position: absolute;
    top: 0;
    left: 0;
    background: #333;
    color: #fff;
    width: 100%;
}

.header-icon {
    // display: inline;
    float: left;
    padding: 0.5rem 0.5rem;
    margin: 0 0.25rem;
}

.header-icon h2 {
    margin: 0;
    font-size: 1.2rem;
}

.content {
    margin-top: 5rem;
}

.result-header {
    width: 100%;
}

.result-count {
    text-align: right;
    margin-right: 2rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 1rem;
}

.filter-pane {
    background: #ccc;
}

.results {
    // background: #ccc;
}

.recipe {
    background: #f4f4f4;
    padding: 1rem 8rem;
    margin-bottom: .5rem;
    margin-right: 2rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.recipe-name {
    font-size: 1.2rem;
}

.tag-filter {
    padding: 0.25rem 0.5rem;
}

.tag-filter input {
    float: left;
}

.tag-filter label {
    text-align: left;
}